.espaisPage-laptop-initialPage-container {
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  height: 100vh;
}

.lazyLoadImage {
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
  object-fit: cover;
}
.laptop-subespaisPage-container {
  height: 100vh;
  display: flex;
}

.laptop-defaultPage-wrapper {
  display: flex;
  flex-direction: column;
}

.laptop-initialPage-container img {
  width: 50%;
  max-width: 50%;
  object-fit: cover;
}

.espaisPage-laptop-initialPage-container img {
  width: 50%;
  max-width: 50%;
  object-fit: cover;
}

@media only screen and (min-width: 700px) {
  .espaisPage-container {
    height: 100%;
    position: relative;
  }
  .espaisPage-carousel-container {
    position: absolute;
    right: 0;
  }
}
