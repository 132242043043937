.cookies-banner__container {
  width: calc(100% - 20px);
  height: 45px;
  padding: 10px;
  position: fixed;
  top: 0;
  background-color: var(--skyblue);
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookies-banner__container p {
  color: var(--mercagreen);
  font-size: 14px;
  letter-spacing: 0.7px;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cookies__close {
  color: var(--mercagreen);
}

.cookies__close:hover {
  cursor: pointer;
}

.cookies__link-span {
  border-bottom: 1px solid var(--mercagreen);
}

.cookies__link-span:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .cookies-banner__container {
    height: 80px;
  }

  .cookies-banner__container p {
    font-size: 12px;
    width: 75%;
    text-align: justify;
    line-height: 15px;
  }
}
