.stores__container {
  width: calc(100% - 20px);
  background-color: var(--mercablue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 70px);
  padding-left: 20px;
  justify-content: center;
}
.stores__section {
  font-size: 16px;
  color: var(--skyblue);
  margin: 0;
}

.stores__list::-webkit-scrollbar {
  width: 20px;
  background-color: var(--skyblue);
}

.stores__list::-webkit-scrollbar-thumb {
  background: var(--skyblue);
  border-left: 7px solid var(--mercablue);
  border-right: 7px solid var(--mercablue);
}
.stores__list::-webkit-scrollbar-track {
  border-left: 9px solid var(--mercablue);
  border-right: 9px solid var(--mercablue);
}

.botigues-link {
  font-family: "Affairs";
  font-size: 14px;
  color: var(--skyblue);
}

.stores__list {
  display: flex;
  flex-direction: column;
  width: 90%;
  overflow-y: scroll;
  margin-top: 5px;
  overflow-x: hidden;
}

.stores__item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--skyblue);
  width: 95%;
}
.stores__item-wrapper p {
  font-size: 14px;
  font-family: Affairs;
  color: var(--skyblue);
  width: calc(100% / 3);
}

.close-button {
  margin-top: 5px;
  align-self: center;
}
.close-button:hover {
  cursor: pointer;
}
