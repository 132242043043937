@import "./normalize.css";
@font-face {
  font-family: "Mercantic";
  src: url("./assets/Typo/assets/FFFMercantic-Regular.woff2") format("woff2"),
    url("./assets//Typo/assets/FFFMercantic-Regular.woff") format("woff");
}

@font-face {
  font-family: "Affairs";
  src: url("./assets/Typo/assets/Affairs-Regular.otf");
}

@font-feature-values "Mercantic" {
  @styleset {
    alt-a: 1;
    alt-g: 1;
  }

  @stylistic {
    alternates: 2;
  }
}
:root {
  --mercagreen: rgba(25, 63, 40, 1);
  --skyblue: #c9dbdc;
  --mercapink: #f298c0;
  --mercabrown: #9b5319;
  --lightgreen: #97f09f;
  --mercablue: #5551e7;
  --mercared: #bf1646;
  --pagination-color: var(--skyblue);
  --swiper-wrapper-padding: 0;
  --fff-mercantic-regular-aalt: "aalt" off;
  --fff-mercantic-regular-case: "case" off;
  --fff-mercantic-regular-dlig: "dlig" off;
  --fff-mercantic-regular-salt: "salt" off;
  --fff-mercantic-regular-sinf: "sinf" off;
  --fff-mercantic-regular-ss01: "ss01" off;
  --fff-mercantic-regular-subs: "subs" off;
  --fff-mercantic-regular-sups: "sups" off;
}
.fff-mercantic-regular-aalt {
  --fff-mercantic-regular-aalt: "aalt" on;
}

.fff-mercantic-regular-case {
  --fff-mercantic-regular-case: "case" on;
}

.fff-mercantic-regular-dlig {
  --fff-mercantic-regular-dlig: "dlig" on;
}

@supports (font-variant-ligatures: discretionary-ligatures) {
  .fff-mercantic-regular-dlig {
    --fff-mercantic-regular-dlig: "____";
    font-variant-ligatures: discretionary-ligatures;
  }
}

.fff-mercantic-regular-salt {
  --fff-mercantic-regular-salt: "salt" on;
}

.fff-mercantic-regular-sinf {
  --fff-mercantic-regular-sinf: "sinf" on;
}

.fff-mercantic-regular-ss01 {
  --fff-mercantic-regular-ss01: "ss01" on;
}

.fff-mercantic-regular-subs {
  --fff-mercantic-regular-subs: "subs" on;
}

@supports (font-variant-position: sub) {
  .fff-mercantic-regular-subs {
    --fff-mercantic-regular-subs: "____";
    font-variant-position: sub;
  }
}

.fff-mercantic-regular-sups {
  --fff-mercantic-regular-sups: "sups" on;
}

@supports (font-variant-position: super) {
  .fff-mercantic-regular-sups {
    --fff-mercantic-regular-sups: "____";
    font-variant-position: super;
  }
}

/* Apply current state of all custom properties
 whenever a class is being applied */
.fff-mercantic-regular-aalt,
.fff-mercantic-regular-case,
.fff-mercantic-regular-dlig,
.fff-mercantic-regular-salt,
.fff-mercantic-regular-sinf,
.fff-mercantic-regular-ss01,
.fff-mercantic-regular-subs,
.fff-mercantic-regular-sups {
  font-feature-settings: var(--fff-mercantic-regular-aalt),
    var(--fff-mercantic-regular-case), var(--fff-mercantic-regular-dlig),
    var(--fff-mercantic-regular-salt), var(--fff-mercantic-regular-sinf),
    var(--fff-mercantic-regular-ss01), var(--fff-mercantic-regular-subs),
    var(--fff-mercantic-regular-sups);
}
html {
  height: 100%;
}

body {
  height: 100%;
}

/* #root {
  height: 100vh; 
} */
body {
  margin: 0;
  font-family: "Mercantic";
  background-color: var(--skyblue);
}
html,
body {
  height: 100%; /* ✔️ Height specified - will fix issue */
}

a {
  text-decoration: none;
}
