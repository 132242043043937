.newsletter-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.03em;
  color: var(--mercagreen);
  text-align: center;
}

.input-container {
  letter-spacing: 0.03em;
  color: #193f28;
  border: none;
  background-color: transparent;
  position: relative;
  outline: none;
  width: 85%;
}

.email-container {
  border-radius: 50px;
  height: 40px;
  border: 2px solid var(--mercagreen);
  background-color: var(--skyblue);
  max-width: calc(100% - 0.25em);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.input-submit {
  border: none;
  background-color: var(--skyblue);
  width: 17px;
  height: 17px;
  padding: 0;
  background-image: url("../../../assets//Newsletter/arrow.svg");
  background-position: center center;
  max-width: 100%;
}

.input-submit:hover {
  cursor: pointer;
}

@media only screen and (min-width: 700px) {
  .newsletter-container {
    padding-top: 0;
    width: 740px;
    margin: auto;
  }
  .newsletter-container p {
    width: fit-content;
    align-self: center;
  }
  .input-submit {
    width: 21px;
    height: 21px;
    background-size: cover;
  }

  .contact__laptop {
    background-color: var(--mercapink);
    color: var(--mercared);
  }
}
