.agenda__container {
  height: 100%;
  position: relative;
  background-color: var(--mercagreen);
  padding-top: 110px;
}

.agenda-mobile-fixingDiv {
  position: fixed;
  top: 50px;
  height: 10px;
  background-color: var(--mercagreen);
  width: 100%;
  z-index: 51;
}
.agenda__day__column {
  display: flex;
  background-color: var(--mercagreen);
  position: fixed;
  top: 50px;
  z-index: 50;
  width: 100%;
}
.overflow {
  overflow-y: hidden;
}
.agenda__day__column__wrapper {
  width: calc(100% / 6);
  padding-left: 15px;
}

.agenda__day__column__wrapper p {
  color: var(--skyblue);
}

.agenda-mobile__month-slide {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
}

.logos-slide {
  display: flex;
  animation: 25s slide infinite linear;
}

.agenda-mobile__month-container {
  background-color: var(--mercagreen);
  position: fixed;
  top: 60px;
  z-index: 50;
  border-top: 1px solid var(--skyblue);
  border-bottom: 1px solid var(--skyblue);
  height: 46px;
  display: flex;
}
.agenda-mobile__month-wrapper {
  display: flex;
  align-items: center;
  color: var(--skyblue);
  display: flex;
  animation: 25s slide infinite linear;
}
.agenda-mobile__month-wrapper span {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.agenda-mobile__month-wrapper span {
  font-size: 20px;
  margin-right: 1rem;
}
.filter-container p {
  color: var(--skyblue);
  font-size: 14px;
  text-align: center;
}

.agenda__row__container {
  flex-direction: column;
  height: auto;
  background-color: var(--mercagreen);
  padding-bottom: 46px;
}

.agenda__row__column {
  width: calc(100% / 6);
}

.agenda__row__column::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 700px) {
  .agenda__row__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
  }
}

@keyframes infiniteloopMobile {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1122.188px, 0, 0);
  }
}
