.homepage-frame1 {
  position: relative;
  width: 100%;
}

.paint {
  height: 100%;
  width: 100%;
}
.test {
  color: red;
}
img {
  display: block;
  max-width: 100%;
}

.homepage-frame2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--skyblue);
}

.homepage-frame2 img {
  width: 200px;
  align-self: center;
}

.frame2-paragraph1 {
  letter-spacing: -0.005em;
  font-size: 28px;
  padding-left: 0.5em;
  padding-right: 2em;
  line-height: 125%;
  color: var(--mercagreen);
}

.frame2-paragraph2 {
  letter-spacing: -0.005em;
  font-size: 28px;
  padding-left: 0.5em;
  line-height: 125%;
  color: var(--mercagreen);
}

.homepage-frame3 {
  margin: 0;
}
.homePageMenu-item-number {
  font-size: 60px;
  position: relative;
  vertical-align: super;
  text-transform: uppercase;
  margin-left: 5px;
}
.homePageMenu-item-number-right {
  font-size: 60px;
  position: relative;
  vertical-align: super;
  text-transform: uppercase;
  margin-right: 5px;
}

.homepage-frame4 {
  background-color: var(--mercagreen);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.homepage-frame5 {
  background-color: var(--skyblue);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 50px;
  padding-bottom: 50px;
}

.homepage-frame5 p {
  font-size: 12.5px;
  line-height: 24px;
  letter-spacing: 0.05em;
  padding-left: 1em;
  color: var(--mercagreen);
}
.homepage-frame5 img {
  width: 200px;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.homepage-frame6 {
  /* height: 60%; */
  background-color: var(--skyblue);
  padding-bottom: 1.5rem;
}

.homepage-frame6-paragraph {
  font-size: 25px;
  color: var(--mercagreen);
  margin: 0;
  margin-left: 15px;
  margin-bottom: 25px;
}

.homepage-frame8 {
  height: 30%;
  background-color: var(--skyblue);
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .scroll-animation-div {
    height: 80px;
  }
  .frame2-paragraph1 {
    font-size: 50px;
    line-height: 66px;
    padding-left: 1em;
    padding-right: 0;
  }
  .frame2-paragraph2 {
    font-size: 50px;
    line-height: 66px;
    padding-left: 45%;
    margin-bottom: 0;
  }
  .homepage-frame2 {
    height: 200%;
    padding-top: 150px;
  }
  .homepage-frame2 img {
    width: auto;
  }

  .homepage-frame3 {
    height: 100vh;
    background-color: var(--skyblue);
    display: flex;
    justify-content: space-between;
  }

  .homepage-frame3-controller {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .homepage-frame3-flip-container {
    width: 50%;
    background-color: var(--mercagreen);
    position: relative;
  }
  .homepage-frame3-flip {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 70px;
    padding-right: 70px;
  }
  .homepage-frame3-flip-footer {
    position: absolute;
    bottom: 1em;
  }
  .homepage-frame3-flip-container p {
    color: var(--skyblue);
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  }
  .homepage-frame3-flip-footer p {
    color: var(--skyblue);
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    margin: 0;
  }
  .homepage-frame3-flip-footer:hover {
    cursor: pointer;
  }

  .homepage-frame3-wrapper {
    height: calc(100% / 3);
    width: 100%;
    align-self: center;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .homepage-frame3 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .homepage-frame3-item-container {
    font-size: 50px;
    color: var(--mercagreen);
    text-align: center;
    margin-top: 3px;
  }
  .homepage-frame3-item-container-noHover {
    font-size: 50px;
    color: var(--mercagreen);
    text-align: center;
    opacity: 0.5;
    margin-top: 3px;
  }
  .homepage-frame3-item-container:hover {
    cursor: pointer;
  }
  .sup-left {
    margin-right: 0.25em;
  }
  .sup-right {
    margin-left: 0.25em;
  }
  .homepage-frame5 {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .homepage-frame5 p {
    font-size: 18px;
    line-height: 34px;

    margin-bottom: 0;
  }
  .homepage-frame5-img-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .homepage-frame5-img-wrapper img {
    height: 600px;
    padding-right: 50px;
    padding-top: 50px;
  }
  .homepage-frame5-img-wrapper p {
    padding: 1em;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .homepage-frame6 {
    height: 60%;
    padding-bottom: 0;
  }
  .homepage-frame6-paragraph {
    font-size: 60px;
    color: var(--mercagreen);
    margin: 0;
    margin-left: 20px;
    margin-bottom: 75px;
  }
  .homepage-frame7 {
    height: auto;
    margin-top: 100px;
  }
  .homepage-frame8 {
    padding-bottom: 80px;
    padding-top: 60px;
  }
}

@keyframes spanAnimation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
