.agenda-card__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 1;
  transition: display 0.5s;
}

.activityDisplay {
  display: none !important;
}

.agenda-card__day {
  background-color: var(--mercagreen);
  padding-left: 15px;
  height: 46px;
  display: flex;
  align-items: center;
}
.agenda-card__day p {
  font-size: 18px;
  color: var(--skyblue);
  margin: 0;
}

.agenda-card__info {
  height: calc(120px - 30px);
  display: flex;
  background-color: var(--skyblue);
  padding: 15px;
  border-bottom: 1px solid var(--mercagreen);
}
.holidays-paragraph {
  color: var(--mercagreen);
  text-align: center;
}
/* ------- LEFT --------*/
.agenda-card__info__left {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

/*----LEFT__UPPER ----*/
.agenda-card__info__left__upper {
  height: 50%;
}
.agenda-card__info__left__upper__section p {
  font-family: "Affairs";
  font-size: 14px;
  color: var(--mercagreen);
  margin: 0;
  line-height: 19px;
}
.agenda-card__info__left__upper__subsection p {
  font-size: 14px;
  color: var(--mercagreen);
  margin: 0;
  line-height: 18px;
}

/*---- LEFT__LOWER ----*/
.agenda-card__info__left__lower {
  height: 50%;
}
.agenda-card__info__left__lower__time {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.agenda-card__info__left__lower__time p {
  font-size: 14px;
  margin: 0;
  color: var(--mercagreen);
}

/*------- RIGHT -------*/
.agenda-card__info__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  height: 100%;
}
/*----RIGHT__UPPER ----*/
.agenda-card__info__right__upper {
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: end;
  width: 80%;
}
.agenda-card__info__right__upper__info {
  width: 50%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
}
.agenda-card__info__right__upper__book:hover {
  cursor: pointer;
}
.agenda-card__info__right__upper__info:hover {
  cursor: pointer;
}
.agenda-card__info__right__upper__info p {
  font-family: "Affairs";
  font-size: 14px;
  color: var(--mercagreen);
  margin: 0;
}
.agenda-card__info__right__upper__book {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.agenda-card__info__right__upper__book p {
  font-family: "Affairs";
  font-size: 14px;
  color: var(--mercagreen);
  margin: 0;
}

/*---- RIGHT__LOWER ----*/

.agenda-card__info__right__lower {
  height: 50%;
  width: 100%;
}
.agenda-card__info__right__lower__additional {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.agenda-card__info__right__lower__additional p {
  font-family: "Affairs";
  font-size: 14px;
  color: var(--mercagreen);
  margin: 0;
}

@media only screen and (min-width: 700px) {
  .agenda-card__container {
    width: calc(100% - 2px);
    border: 1px solid var(--mercagreen);
  }
  .display {
    /* opacity: 0;
    display: block; */
    display: none;
  }
  .agenda-card__day {
    height: auto;
  }
  .agenda-card__day p {
    margin: auto;
  }
  .agenda-card__day-laptop {
    font-size: 16px !important;
  }
  .agenda-card__number-laptop {
    font-size: 40px !important;
    margin: 0;
    margin-bottom: 15px;
    letter-spacing: 0.05em;
  }
  .agenda-card__info {
    flex-direction: column;
    height: calc(140px - 30px);
    border-bottom: 0;
  }
  .agenda-card__info__left {
    width: 100%;
  }
  .agenda-card__info__left__upper {
    height: auto;
  }
  .agenda-card__info__left__upper__subsection {
    margin-top: 3px;
    line-height: 22px;
  }
  .agenda-card__info__left__lower__time {
    align-items: flex-start;
    padding-top: 3px;
  }

  .agenda-card__info__right {
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .agenda-card__info__right__upper {
    width: auto;
    text-align: left;
  }

  .agenda-card__info__right__upper__info {
    margin-right: 20px;
  }
  .agenda-card__info__right__upper__book {
    width: 50%;
  }

  .agenda-card__info__right__lower__additional {
    align-items: flex-start;
  }

  .type-ball {
    background-color: var(--mercagreen);
    height: 10px;
    width: 10px;
    border-radius: 100%;
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
