.agenda-column__container {
  width: calc(100% / 6);
  height: 100%;
}

.agenda-column__day__wrapper {
  padding-left: 15px;
}

.agenda-column__day__wrapper p {
  margin: 0;
  color: var(--skyblue);
}

.agenda-column__day__day {
  font-size: 16px;
  padding-top: 5px;
}

.agenda-column__day__number {
  font-size: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
}

/* .agenda-column__container {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
/* scrollbar-width: none; /* Firefox 
}*/

/*.agenda-column__container::-webkit-scrollbar {
  display: none;
} */

.agenda-column__wrapper {
  max-height: 490px;
  overflow: auto;
}
