.gastronomiaPage-frame2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #9b5319;
  height: 85vh;
  @media only screen and (min-width: 700px) {
    height: 100vh;
    width: 100%;
    position: static;
  }
}

.gastronomiaPage-frame2-top-wrapper {
  color: #f298c0;
  padding-bottom: 40px;
}

.gastronomiaPage-frame2-top-wrapper h3 {
  font-size: 38px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.005em;
  color: "F298C0";
  margin: 0;
  @media only screen and (min-width: 700px) {
    font-size: 60px;
    line-height: 66px;
  }
}

.gastronomiaPage-frame2-top-wrapper p {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 0;
  @media only screen and (min-width: 700px) {
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    padding-left: 120px;
    padding-right: 120px;
  }
}

.gastronomiaPage-frame2-bottom-wrapper {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 70px; */
}
.gastronomiaPage-frame2-bottom-wrapper p {
  margin: 0;
  font-family: "Affairs";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #f298c0;
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 700px) {
  .gastronomiaPage-frame2-bottom-wrapper p {
    padding-left: 110px;
    padding-right: 110px;
  }
}
