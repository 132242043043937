.footer-links {
  display: flex;
  flex-direction: column;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0;
}

.footer-links-wrapper p:hover {
  cursor: pointer;
}

.footer-links-wrapper a:first-child {
  margin-top: 0;
}
.footer-info {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-info-address {
  display: flex;
  flex-direction: column;
}

.footer-info-schedule {
  margin-top: 2em;
}

.footer__logo {
  padding: 1em;
  padding-bottom: 1.5em;
  padding-top: 0;
}

.footer__logo img {
  width: 100%;
}
.footer__link__paragraph:hover {
  cursor: pointer;
}
@media only screen and (min-width: 600px) {
  .footer-container {
    justify-content: space-between;
    position: sticky;
    top: 0;
  }
  .footer-laptop-container {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
  }
  .footer-links {
    width: calc(100% / 3);
    flex-direction: row;
    padding-bottom: 1em;
  }
  .footer-links-wrapper {
    flex-direction: column;
    width: 50%;
    border: none;
    justify-content: flex-start;
  }
  .footer-links-wrapper p {
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    height: 2%;
  }

  .footer-text {
    width: calc(100% / 3);
  }

  .footer-text p {
    width: 80%;
    margin-top: 0.5em;
  }

  .footer-links-wrapper p:first-child {
    margin-top: 0;
  }

  .footer-info {
    align-items: flex-start;
    padding-top: 0.5em;
    justify-content: space-between;
    width: calc(100% / 3);
    height: 180px;
    padding-left: 5%;
  }

  .footer-info-address p {
    text-align: left;
  }

  .footer-info-schedule p {
    text-align: left;
  }
  .footer-info-schedule {
    margin-top: 0;
  }
  .footer__logo img {
    height: 100% !important;
  }
}
