.homepage-frame4-title {
  font-size: 38px;
  margin-top: 0;
  color: var(--skyblue);
  text-align: center;
  line-height: 110%;
  letter-spacing: -0.005em;
}

.homepage-frame4-text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--skyblue);
  padding-left: 1em;
  padding-right: 1em;
}

.paging-dot {
  height: 10px;
  width: 10px;
}

.slider-container {
  height: 60%;
  margin-bottom: 2em;
}

.swiper {
  position: unset;
  height: 100%;
}

.swiper-container {
  position: unset;
  padding-top: 50px;
}

.swiper-wrapper {
  position: unset;
}

.swiper-slide {
  height: auto;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 35px;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--skyblue);
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}
