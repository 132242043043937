.defaultCarousel-container {
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: 0;
  height: 80vh;
  z-index: 1;
}

.default-carousel__img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: 1;
  position: relative;
}
.swiper-button-next {
  width: 50%;
  opacity: 0 !important;
  top: 0;
  height: 100vh;
}

.swiper-button-next:focus {
  background-color: transparent;
}

.swiper-button-prev:focus {
  background-color: transparent;
}
.swiper-button-prev {
  width: 50%;
  opacity: 0 !important;
  top: 0;
  height: 100vh;
}

.swiper-pagination-bullet {
  border: 1.5px solid var(--pagination-color);
  opacity: 1;
  background-color: transparent;
  color: var(--pagination-color);
}
.swiper-pagination-bullet-active {
  background-color: var(--pagination-color) !important;
}
.swiper {
  height: 100%;
  position: relative;
}
.swiper-wrapper {
  padding-top: var(--swiper-wrapper-padding);
}
.lazy-load-image-background {
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 900px) {
  .defaultCarousel-container {
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 50%;
  }
  .swiper {
    width: 100%;
    padding-top: 0;
    height: 100%;
  }
  .swiper-horizontal .swiper-pagination {
    bottom: 70px;
  }
}
