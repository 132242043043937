.esdevenimentPage-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--skyblue);
}

.esdevenimentPage-content-container {
  display: flex;
  flex-direction: column;
  height: calc(95vh - 130px);
  justify-content: space-between;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 30px;
}

.esdevenimentPage-content-title {
  letter-spacing: -0.14px;
  font-size: 28px;
  color: var(--mercagreen);
  margin-bottom: 10px;
}

.esdevenimentPage-content-text {
  font-size: 14px;
  letter-spacing: 0.7px;
  color: var(--mercagreen);
  margin: 0 35px;
  line-height: 19px;
}

.esdevenimentPage-content-footer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: var(--mercagreen);
}

.esdevenimentPage-img {
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .esdevenimentPage-img {
    width: 50%;
    height: 100%;
  }

  .esdevenimentPage-container {
    flex-direction: row;
    height: 100vh;
  }
  .esdevenimentPage-content-container {
    width: 50%;
  }
  .esdevenimentPage-content-text {
    margin: 0 80px;
    line-height: 25px;
    font-size: 16px;
  }
  .empresaPage-link {
    color: var(--mercagreen);
  }
  .empresaPage-link:hover {
    cursor: pointer;
  }
}
