.tallerDefaultPage-container {
  height: 80vh;
  background-color: var(--mercagreen);
  color: var(--lightgreen);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.taller-top-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
}
.top-wrapper-place {
  margin-top: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
}

.top-wrapper-title {
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.005em;
  margin: 0;
}
.counter {
  margin-top: 0 !important;
}
.middle-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.middle-wrapper-text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}

.middle-wrapper-moreInfo {
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: var(--lightgreen);
  margin-top: 20px;
}
.middle-wrapper-moreInfo:hover {
  cursor: pointer;
}

.list-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em;
  font-family: "affairs";
  padding-bottom: 0;
}

.bullet-point {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--lightgreen);
  margin: 0;
  margin-right: 2px;
  margin-left: 5px;
}

.item-list-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 0.25em;
  letter-spacing: -0.1px;
}

@media only screen and (min-width: 700px) {
  .tallerDefaultPage-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    position: relative;
  }
  .taller-top-wrapper {
    padding-top: 0;
  }
  .top-wrapper-title {
    font-size: 60px;
    line-height: 110%;
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .top-wrapper-place {
    font-size: 16px;
    line-height: 27px;
  }
  .middle-wrapper-text {
    font-size: 16px;
    line-height: 27px;
    padding-left: 100px;
    padding-right: 100px;
    margin: 0;
  }
  .list-wrapper {
    font-size: 14px;
    line-height: 174%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    padding-left: 100px;
    padding-right: 100px;
    /* width: calc(100% - 300px); */
    padding-bottom: 3em;
  }

  .bullet-point {
    margin-right: 2px;
    margin-left: 7px;
  }
}
