.laptop-top-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  object-fit: cover;
}

.laptop-top-wrapper img {
  width: 50%;
  object-fit: cover;
}

.defaultpage-wrapper {
  display: flex;
  height: 120%;
}

.defaultpage-img-container {
  width: 50%;
}
.defaultpage-img-container img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
