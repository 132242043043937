.dropdown-pater {
  height: 100%;
  z-index: 999;
  overflow: hidden;
}

.dropdown-items div:hover {
  cursor: pointer;
}
.dropdown-languages {
  display: flex;
  justify-content: flex-start;
  z-index: 5;
  margin-bottom: 10px;
}

.dropdown-languages p {
  padding-left: 20px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-languages p:hover {
  cursor: pointer;
}

.item-wrapper {
  padding-left: 15px;
}

.dropdown-footer {
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-left: 1em;
  padding-right: 1em;
}
.dropdown-footer p {
  margin: 0;
}
.dropdownMenu-backgroundImage-mobile {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 87svh;
  object-fit: cover;
}
.dropdown-items {
  z-index: 5;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 15px;
}
@media only screen and (min-width: 700px) {
  .dropdown-footer {
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    flex-direction: row;
  }

  .dropdown-items {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 55px;
  }
  .dropdown-languages {
    height: min-content;
  }

  .dropdown-languages p {
    margin: 0;
    height: min-content;
    padding-left: 0;
    margin-left: 20px;
  }
  .dropdown-languages p:hover {
    border-bottom: 1px solid;
  }
}

.laptop-footer-wrapper {
  display: flex;
}

.laptop-footer-wrapper p {
  margin: 0;
  margin-right: 15px;
}

.laptop-footer-wrapper p:hover {
  border-bottom: 1px solid;
}
