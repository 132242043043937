.homepage-frame4-title {
  font-size: 38px;
  margin-top: 0;
  color: var(--skyblue);
  text-align: center;
  line-height: 110%;
  letter-spacing: -0.005em;
}

.homepage-frame4-text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--skyblue);
  padding-left: 1em;
  padding-right: 1em;
}

.paging-dot {
  height: 10px;
  width: 10px;
}

.slider-container {
  /* height: 60%; */
  margin-bottom: 2em;
}

.table-container {
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
}

.table-item-wrapper {
  border-bottom: 1px solid var(--skyblue);
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 27px;
}

.table-title {
  color: var(--skyblue);
  font-family: "Mercantic";
}

.table-item-wrapper p {
  font-size: 12px;
  line-height: 174%;
  color: var(--skyblue);
}

.bullet-container {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bullet-selected {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--mercablue);
  display: block;
  margin-right: 5px;
}

.bullet-default {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--skyblue);
  border: 1px solid var(--mercablue);
  display: block;
  margin-right: 5px;
  box-sizing: border-box;
}

.selected-not-first {
  background-color: var(--skyablue);
}

.default-not-first {
  border: 1px solid var(--skyblue);
  background-color: var(--mercablue);
}
