.tallersPage-container {
  height: 100vh;
  position: relative;
}

.laptop-wrapper {
  display: flex;
  height: 100vh;
}
.laptop-wrapper img {
  width: 100%;
  object-fit: cover;
  max-height: 100%;
}
.laptop-wrapper-initialPage {
  flex-direction: row-reverse;
}
.agenda-banner-container {
  height: 40px;
  background-color: var(--lightgreen);
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 210;
}

.agenda-banner-container p {
  color: var(--mercagreen);
}

.tallersPage-agenda-fixed-menu-container {
  position: fixed;
  top: 45%;
  left: 46%;
  z-index: 990;
}

.tallersPage-agenda-fixed-menu {
  writing-mode: vertical-rl;
  color: var(--lightgreen);
  font-size: 16px;
  transform: rotate(-180deg);
}

.tallersPage-agenda-fixed-menu:hover {
  cursor: pointer;
}
