.cookies__container {
  height: calc(100vh);
  width: 100%;
  background-color: var(--skyblue);
}
.cookies__section-wrapper {
  padding-top: 30%;
}
.cookies__section {
  padding: 20px;
}
.cookies__section h3 {
  color: var(--mercagreen);
  font-weight: normal;
}
.cookies__section p {
  line-height: 2;
  color: var(--mercagreen);
}

@media screen and (min-width: 600px) {
  .cookies__container {
    height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cookies__section-wrapper {
    padding-top: 0;
    height: 70%;
    width: 50%;
  }
  .cookies__image {
    width: 50%;
    height: 100%;
    object-fit: cover;
  }
}
