.contactPage__pater {
  height: 100svh;
  width: 100%;
  background-color: var(--mercapink);
}
.overflow {
  overflow-y: hidden;
}
.last-child {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--mercapink);
  margin-bottom: 0 !important;
}

.test {
  background-color: aliceblue;
}
.contactPage__container {
  background-color: var(--mercared);
  padding-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
}
.contactPage__content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contactPage__content {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  height: 100vh;
  font-size: 14px;
  color: var(--mercapink);
  padding: 15px;
  padding-left: 20px;
  text-align: left;
  padding-top: 17%;
  padding-bottom: 0px;
  position: relative;
}

.contactPage__content p {
  margin: 0.5rem;
  margin-left: 0;
  margin-right: 0;
}
.contactPage__languages {
  display: flex;
  justify-content: flex-start;
}
.contactPage__languages p {
  padding-left: 20px;
  color: var(--mercapink);
}
.contactPage__languages:hover {
  cursor: pointer;
}
.contactePage__footer-wrapper {
  background-color: var(--mercapink);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.contactPage__footer {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
}
.contactPage__footer p {
  color: var(--mercared);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 0;
  margin-bottom: 15px;
}
.contactPage__footer p:hover {
  cursor: pointer;
}

@media only screen and (min-width: 700px) {
  .contactPage__container {
    height: 100%;
    justify-content: center;
    padding-top: 0;
  }
  .contactPage__content {
    height: calc(92vh);
    display: flex;
    margin: auto;
    font-size: 16px;
    flex-direction: row;
    width: 100%;
    padding: 0;
    text-align: left;
  }
  .contactPage__content p {
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 20px);
  }
  .contactPage__container a:hover {
    border-bottom: 1px solid var(--mercapink);
  }
  .contactPage-margin-info1-container {
    margin-bottom: 15px;
  }

  .contactPage__content__left {
    position: relative;
    padding-left: 20px;
    width: 50%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .contactPage__content__right {
    width: 50%;
    background-color: var(--mercapink);
  }
  .contactPage__content__right p {
    margin: 1.5px;
  }
  .contactPage__content__right__first {
    height: 50%;
    margin: 0;
    padding: 11px;
    width: calc(100% - 22px);
  }
  .contactPage__content__right__second {
    height: 50%;
    margin: 0;
    border-top: 1px solid var(--mercared);
    width: calc(100% - 22px);
    padding-top: 11px;
  }
  .contactPage__footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mercapink);
    height: 8vh;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .footer-laptop-wrapper {
    display: flex;
  }

  .contactPage__languages p {
    margin: 0;
    padding: 0;
    margin-left: 15px;
    font-size: 16px;
  }
  .contactPage__languages p:hover {
    border-bottom: 1px solid;
  }
  .footer-laptop-wrapper p {
    margin: 0;
    margin-right: 15px;
    font-size: 16px;
    height: min-content;
  }
  .footer-laptop-wrapper p:hover {
    border-bottom: 1px solid;
  }
  .contactPage__newsletter-container {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
