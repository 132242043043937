.mercatsDefaultPage-container {
  height: 80vh;
  color: var(--skyblue);
  background-color: var(--mercared);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 700px) {
    height: 100vh;
    width: 100%;
    justify-content: center;
    position: relative;
  }
}
.top-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
  @media only screen and (min-width: 700px) {
    padding-top: 0;
  }
}

.top-wrapper-title {
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.005em;
  margin-top: 0;
  @media screen and (min-width: 700px) {
    font-size: 60px;
    line-height: 110%;
    margin-bottom: 0.5em;
  }
}

.market-a {
  display: inline;
  text-decoration: none;
  color: var(--skyblue);
}

.market-a:hover {
  cursor: pointer;
}
.middle-wrapper-text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  @media screen and (min-width: 700px) {
    font-size: 16px;
    line-height: 27px;
    padding-left: 100px;
    padding-right: 100px;
    margin: 0;
  }
}

.mercatsPage-contact-wrapper {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 1em;
}
.mercatsPage-contact-wrapper p {
  margin-left: 30px;
  margin-right: 30px;
}
@media only screen and (min-width: 700px) {
  .mercatDefaultPage-pater {
    height: 100vh;
  }

  .mercatsPage-contact-wrapper {
    font-size: 16px;
    line-height: 27px;
    padding: 0;
    width: 100%;
    padding-bottom: 3em;
  }
}
