@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.carousel2-container {
  overflow: hidden;
  padding: 20px 0;
  background: var(--skyblue);
  white-space: nowrap;
  position: relative;
  display: flex;
}

.carousel-slides {
  display: flex;
  animation: 70s slide infinite linear;
}

.carousel-slides img {
  max-width: 250px;
  width: 250px;
  height: 350px;
  margin: 0 20px;
  object-fit: cover;
}
@media only screen and (min-width: 700px) {
  .carousel-slides img {
    max-width: 350px;
    width: 400px;
    height: 450px;
    margin: 0 40px;
  }
  .carousel2-container {
    padding-bottom: 50px;
  }
}
