.espaisDefaultPage-container {
  height: 100%;
  background-color: var(--skyblue);
  color: var(--mercablue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media only screen and (min-width: 700px) {
    width: 100%;
    justify-content: center;
    height: 100vh;
  }
}

.top-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
  @media only screen and (min-width: 700px) {
    padding-top: 0;
    margin-left: 80px;
    margin-right: 80px;
  }
}

.top-wrapper-title {
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.005em;
  margin-top: 0;
  @media screen and (min-width: 700px) {
    font-size: 60px;
    line-height: 110%;
    margin-bottom: 0.5em;
  }
}

.middle-wrapper-text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
  @media screen and (min-width: 700px) {
    font-size: 16px;
    line-height: 27px;
    padding-left: 100px;
    padding-right: 100px;
    margin: 0;
  }
}

.contact-wrapper {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 1em;
  @media only screen and (min-width: 700px) {
    font-size: 16px;
    line-height: 27px;
    padding: 0;
    width: 100%;
    padding-bottom: 1em;
  }
}

.vertical-navbar {
  box-sizing: border-box;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.vertical-navbar:hover {
  cursor: pointer;
}
.vertical-navbar p:hover {
  border-left: 1px solid;
}
