.agenda-filter__container {
  position: fixed;
  bottom: 0;
  height: calc(40px - 20px);
  display: flex;
  justify-content: flex-start;
  background-color: var(--mercagreen);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
}
.agenda-filter__item-wrapper {
  margin-left: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agenda-month__container {
  height: 40px;
  background-color: var(--mercagreen);
  position: fixed;
  top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--skyblue);
  display: flex;
  justify-content: space-around;
  z-index: 999;
  overflow: hidden;
  min-width: fit-content;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
}

.agenda-month__container p {
  padding-right: 1rem;
  margin-bottom: 0;
}
.agenda-month__wrapper {
  display: flex;
  animation: 25s slide infinite linear;
}

.agenda-filter__item-wrapper:hover {
  cursor: pointer;
}
.agenda-filter__item-wrapper p {
  color: var(--skyblue);
  font-size: 16px;
  margin: 0;
  letter-spacing: 0.8px;
}

.agenda-filter__item-wrapper-id0 {
  display: flex;
  justify-content: space-around;
  align-self: flex-end;
}
.agenda-filter__item-wrapper-id0 p {
  font-family: "Affairs";
  font-size: 12px;
  margin: 0;
  color: var(--skyblue);
  letter-spacing: 0.6px;
  font-weight: bold;
}

.id0-bullet {
  height: 11px;
  width: 11px;
  background-color: var(--skyblue);
  margin: 0;
  border-radius: 100%;
  align-items: center;
  margin-right: 5px;
}

.filter-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 46px;
  background-color: var(--mercagreen);
  border: 0.5px solid var(--skyblue);
}
.opacity-filter {
  opacity: 0.5;
}

@keyframes infiniteloop {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1122.188px, 0, 0);
  }
}
@media only screen and (min-width: 700px) {
  .agenda-month__container {
    height: calc(20px - 10px);
    position: fixed;
    background-color: var(--mercagreen);
    width: 100%;
    bottom: 40px;
    top: auto;
    display: flex;
    align-items: center;
    color: var(--skyblue);
    display: flex;
    justify-content: space-around;
    overflow: visible;
    left: 0;
    right: 0;
    padding-top: 10px;
  }
  .agenda-month__container p {
    margin: 0;
  }
}
