.subespaisDefaultPage-pater {
  height: 100%;
  width: 100%;
  display: flex;
}

.subespaisDefaultPage-container {
  background-color: var(--mercablue);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.subespaisDefaultPage-pater {
  width: 100%;
  display: flex;
  height: 100%;
}

.subespaisDefaultPage-pater img {
  width: 100%;
  object-fit: cover;
}

.subespaisDefaultPage-wrapper {
  display: flex;
  flex-direction: column;
}

.restorationController {
  height: 100%;
  justify-content: center;
}
.subespaisDefaultPage-wrapper-title {
  color: var(--skyblue);
  margin: 0;
  padding-top: 0;
  font-size: 60px;
  line-height: 66px;
  letter-spacing: -0.005em;
  text-align: center;
  padding-left: 70px;
  padding-right: 70px;
}

.subespaisDefaultPage-wrapper-section {
  color: var(--skyblue);
  margin: 0;
  padding-left: 25px;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-align: center;
}
.subespaisDefaultPage-wrapper-text {
  color: var(--skyblue);
  font-size: 16px;
  line-height: 27px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 1em;
  text-align: center;
  margin: 0rem;
}
.subespaisDefaultPage-wrapper-text2 {
  color: var(--skyblue);
  font-size: 16px;
  line-height: 27px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 1em;
  text-align: center;
  padding-bottom: 1em;
}

.restorationParagraph {
  color: var(--skyblue);
  font-size: 16px;
  line-height: 27px;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
  margin: 0;
}
.link-container {
  display: flex;
  justify-content: space-between;
  width: calc(200% - 40px);
  background-color: var(--mercablue);
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40px;
  z-index: 5;
}

.link {
  color: var(--skyblue);
  align-self: center;
  margin: 0;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.05em;
}

.link:hover {
  cursor: pointer;
}

.vertical-link-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 50px;
  background-color: var(--mercablue);
  /* padding-left: 20px; */
  position: absolute;
  padding-top: 20px;
  padding-bottom: 20px;
  right: 0;
  bottom: 0;
  height: calc(100% - 40px);
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  /* margin-bottom: 5%;
  margin-top: 5%; */
}

.height-adjusted {
  /* height: 80%;
  margin-bottom: 10%;
  margin-top: 10%; */
}
.vertical-link {
  color: var(--skyblue);
  align-self: center;
  margin: 0;
  text-align: center;
  font-size: 16px;
  line-height: 27px;
  width: max-content;
  opacity: 0.5;
}

.opacity {
  opacity: 1;
}
.vertical-link:hover {
  border-left: 1px solid;
  cursor: pointer;
}

.cross {
  height: 60px !important;
  width: 60px !important;
}

.cross:hover {
  cursor: pointer;
}
.central-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
