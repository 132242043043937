.esdevenimentPage-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--skyblue);
}

.esdevenimentPage-content-container {
  display: flex;
  flex-direction: column;
  height: calc(95vh - 170px);
  justify-content: space-between;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 70px;
}

.esdevenimentPage-content-title {
  letter-spacing: -0.14px;
  font-size: 28px;
  color: var(--mercagreen);
}

.esdevenimentPage-content-text {
  font-size: 14px;
  letter-spacing: 0.7px;
  color: var(--mercagreen);
  margin: 0 35px;
}

.esdevenimentPage-content-footer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: var(--mercagreen);
}
.esdevenimentPage-content-footer p:hover {
  cursor: pointer;
}
.empresaPage-link {
  color: var(--mercagreen);
}
.esdevenimentPage-img {
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .esdevenimentPage-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .esdevenimentPage-container {
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
  }
  .esdevenimentPage-content-text {
    font-size: 16px;
    letter-spacing: 0.7px;
    color: var(--mercagreen);
    margin: 0 60px;
  }
  .esdevenimentPage-content-container {
    width: 50%;
  }
}
