.mercatsPage-container {
  height: 100vh;
}

/* .mercatsPage-container img {
  height: 100%;
} */
/* .default-img {
  min-height: 80%;
  object-fit: cover;
} */
.laptop-wrapper-initialPage {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
}
.marketsPage-laptop-wrapper {
  display: flex;
  flex-direction: column;
}
.laptop-wrapper img {
  width: fit-content;
  object-fit: cover;
  max-height: 100%;
}
.laptop-wrapper-initialPage img {
  width: 50%;
  object-fit: cover;
  max-height: 100%;
}

@media only screen and (min-width: 700px) {
  .mercatsPage-container img {
    height: 100%;
  }
}
